import request from "@/utils/request";

/**
 * 列表&&套餐卡
 */
export function getMalllManageMentList(params) {
  return request({
    url: "seller/shops/getShopChilds",
    method: "get",
    loading: false,
    params
  });
}

/**
 * 套餐卡商城列表
 */
export function getComboShopList (params) {
  return request({
    url: "seller/shops/getShopComboChildsTh",
    method: "get",
    loading: false,
    params
  });
}
/**
 * 添加
 */
export function addChildShop(data) {
  return request({
    url: "seller/shops/addChildShop",
    method: "post",
    loading: true,
    data
  });
}

/**
 * 添加2.0
 */
export function addChildShopTh(data) {
  return request({
    url: "seller/shops/addChildShopTh",
    method: "post",
    loading: true,
    data
  });
}

//
/**
 * 企业添加子店铺基本信息验证（第一步）
 */
export function addChildShopVerification(data) {
  return request({
    url: "/seller/shops/addChildShopVerification ",
    method: "post",
    loading: true,
    data
  });
}

/**
 * 点击管理项目进行跳转
 */
export function loginChild(data) {
  return request({
    url: `seller/shops/login/child/${data}`,
    method: "get",
    loading: false
  });
}

/**
 * 查询是否在复制店铺
 */
export function checkCopyShop(data) {
  return request({
    url: "seller/shops/checkCopy",
    method: "post",
    loading: false,
    data
  });
}

/**
 * 查询是否在复制套餐卡店铺
 */
export function checkCopyShopCombo(data) {
  return request({
    url: "seller/shops/checkCopyCombo",
    method: "post",
    loading: false,
    data
  });
}

/**
 * 查询是否在复制双选商城店铺
 */
export function checkCopyShopSX(data) {
  return request({
    url: "seller/shops/checkCopySX",
    method: "post",
    loading: false,
    data
  });
}
/**
 * 复制套餐卡2.0店铺
 */
export function copyShopTh(data) {
  return request({
    url: "seller/shops/copyChildShopTh",
    method: "post",
    loading: true,
    data
  });
}

/**
 * 复制店铺
 */
export function copyShop(data) {
  return request({
    url: "seller/shops/copyChildShop",
    method: "post",
    loading: true,
    data
  });
}

/**
 * 修改智能套餐卡售后审核状态
 */
export function changeAIComboShopAfterSaleStatus(params) {
  return request({
    url: `/seller/after-sales/setAfterSaleSwitchTh`,
    method: 'get',
    loading: false,
    params
  })
}
/**
 * 验证域名是否重复
 */
export function checkDomainName(domain_name) {
  return request({
    url: `/seller/shops/checkDomainName`,
    method: 'get',
    loading: false,
    params:{domain_name:domain_name}
  })
}
